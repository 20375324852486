import React from "react"

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(faPaperPlane);

class ContactForm extends React.Component {

    textSizeBig = false;

    generateSentence = (context) => {
        switch (context.type) {
            case 'position':
                switch (context.position) {
                    case "qa": this.textSizeBig = true; return "Good day, SOMMER team.\r\nI'm interested to discuss a possible hire of Quality Assurance Specialist.\r\nBest regards.";
                    case "ba": this.textSizeBig = true; return "Good day, SOMMER team.\r\nI'm interested to discuss a possible hire of Business Analyst.\r\nBest regards.";
                    case "ds": this.textSizeBig = true; return "Good day, SOMMER team.\r\nI'm interested to discuss a possible hire of Data Analytic (or Scientist).\r\nBest regards.";
                    case "pm": this.textSizeBig = true; return "Good day, SOMMER team.\r\nI'm interested to discuss a possible hire of Project Manager.\r\nBest regards.";
                    case "po": this.textSizeBig = true; return "Good day, SOMMER team.\r\nI'm interested to discuss a possible hire of Product Owner.\r\nBest regards.";
                    case "sd": this.textSizeBig = true; return "Good day, SOMMER team.\r\nI'm interested to discuss a possible hire of Software Developer.\r\nBest regards.";
                    default: this.textSizeBig = false; return "Good day, SOMMER team.\r\nI'm interested to discuss a possible hire of team.\r\nBest regards.";
                }
            case 'step':
                const firstPart = "Good day, SOMMER team.\r\nCould you please tell me more about your process.";
                const endPart = "\r\nBest regards.";
                switch (context.step) {
                    case "inspect": this.textSizeBig = true; return firstPart + " Especially regarding candidates CVs." + endPart;
                    case "interview": this.textSizeBig = true; return firstPart + " Especially regarding interview stage." + endPart;
                    case "board": this.textSizeBig = true; return firstPart + " Especially regarding board stage." + endPart;
                    default: this.textSizeBig = false; return firstPart + endPart;
                }
            default: this.textSizeBig = false; return "";
        }
    }

    render() {
        let formRole = this.props.formRole ?? 'business';
        let formReferrer = this.props.formReferrer ?? 'unknown';
        let formClickLocation = this.props.formClickLocation ?? 'unknown';
        
        let formContext = this.props.formContext ?? 'unknown';
        // context.type
        // for type=position
        // .position = [qa, ba, ds, pm, po, sd]
        // for type=step
        // .step = [inspect, interview, board]

        return (
            <>
                <form
                    method="POST"
                    action="https://docs.google.com/forms/d/e/1FAIpQLSc80K9p4BDHtZ7y6WqOifH_heTjWIxmRONMS0QPUcXTAYyF1w/formResponse">
                        <input type="hidden" name="entry.62196186" value={formReferrer + ',' + formClickLocation} readOnly={true} />
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <input className="form-control" id="name" name="entry.1156562535" placeholder="Name" type="text" required />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <input className="form-control" id="email" name="entry.861470162" placeholder="Email" type="email" required />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <select className="nice-select" id="type" name="entry.2022440122" defaultValue={formRole}>
                                    <option value="business" default>I represent an Employer</option>
                                    <option value="doctor">I am a Medical Doctor</option>
                                    <option value="agent">I represent agency</option>
                                    <option value="3rd-party">Other</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <textarea
                                    placeholder="Any clues for us to prepare a presentation"
                                    type="text" id="desc" name="entry.573121863" className="form-control"
                                    defaultValue={this.generateSentence(formContext)}
                                    rows={this.textSizeBig ? 4 : 2}></textarea>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <button type="submit" name="submit" id="submit">
                                Submit request <i><FontAwesomeIcon icon={['fas', 'paper-plane']} /></i>
                            </button>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}

export default ContactForm;
