
import React from "react";
import PropTypes from "prop-types";
import CookieConsent from 'react-cookie-consent';
import {Link} from 'gatsby';




const Layout = ({ children }) => {

  return (
    <>
        {children}
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics">
          We use cookies to ensure your best experience. By continuing to browse this site, you accept the use of cookies and “third-party” cookies. For more information or to refuse consent to some cookies, please see our <Link to="/terms-and-conditions">Terms and Conditions</Link> and <Link to="/cookies-policy">Cookie Policy</Link>.
        </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout