import React from "react"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SideMenu from "./side-menu"
// import Navbar from "./navbar"

import { Link } from "gatsby"

import LogoFullWhite from '../assets/images/logo-full-white.inline.svg';
import LogoFullBlue from '../assets/images/logo-full-blue.inline.svg';


library.add(faBars);



function handleDummy() {}

class HeaderSimple extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        
    }


    onNavbarToggleClick = (e)=>{
        const thisButton = e.currentTarget;
        const menu = this.myRef.current.querySelector(
            thisButton.dataset.target
        );

        if (thisButton.getAttribute('aria-expanded') === "false") {
            menu.classList.add('show');
            thisButton.setAttribute('aria-expanded', "true");
        } else {
            
            menu.classList.remove('show');
            thisButton.setAttribute('aria-expanded', "false");
        }
    }

  
    componentWillUnmount() {
  
    }
  
    render() {
      return (
        <>
            {/* <!-- Header  */}
            {/* ============================================= --> */}
            <header id="home" ref={this.myRef}>

                {/* <!-- Start Navigation --> */}
                <nav className="navbar navbar-default navbar-fixed background-less white no-background bootsnav">

                    {/* <!-- Start Top Search --> */}
                    {/* <div className="container">
                        <div className="row">
                            <div className="top-search">
                                <div className="input-group">
                                    <form action="#">
                                        <input type="text" name="text" className="form-control" placeholder="Search" />
                                        <button type="submit">
                                            <i className="ti-search"></i>
                                        </button>  
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <!-- End Top Search --> */}

                    <div className="container">

                        {/* <!-- Start Atribute Navigation --> */}
                        <div className="attr-nav">
                            <ul>
                                {/* <li className="search"><a href={handleDummy()}><i className="ti-search"> </i></a></li> */}
                                {/* <li className="side-menu"><a href={handleDummy()}><i className="ti-menu-alt"> </i></a></li> */}
                            </ul>
                        </div>        
                        {/* <!-- End Atribute Navigation --> */}

                        {/* <!-- Start Header Navigation --> */}
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle"
                            data-toggle="collapse" data-target="#navbar-menu" aria-expanded={false}
                            onClick={this.onNavbarToggleClick}>
                                <i><FontAwesomeIcon icon={['fas', 'bars']} /></i>
                            </button>

                            <a className="navbar-brand" href="/">
                                <div className="logo logo-display">
                                    <LogoFullWhite alt="Sommer logo"/>
                                </div>
                                <div className="logo logo-scrolled">
                                    <LogoFullBlue alt="Sommer logo"/>
                                </div>
                            </a>
                        </div>
                        {/* <!-- End Header Navigation --> */}



                        {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
                        <div className="collapse navbar-collapse" id="navbar-menu">
                            <ul className="nav navbar-nav navbar-left" data-in="fadeInDown" data-out="fadeOutUp">
                                <li>
                                    <Link to="/" activeClassName="active">Businesses</Link>
                                </li>
                                <li>
                                    <Link to="/careers/" activeClassName="active">Careers</Link>
                                </li>
                                <li style={{'display':'none'}}>
                                    <a href={handleDummy()}>Contacts</a>
                                </li>
                                <li style={{'display':'none'}}>
                                    <a href={handleDummy()}>Blog</a>
                                </li>
                            </ul>
                        </div>
                        {/* <!-- /.navbar-collapse --> */}
                    </div>


                    <SideMenu></SideMenu>

                </nav>
                {/* <!-- End Navigation --> */}

            </header>
            {/* <!-- End Header --> */}

            </>
        )
    }
}

export default HeaderSimple;