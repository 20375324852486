import React from "react"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


library.add(fab);

function handleDummy(e) {}

export default () => (
  <>
            {/* <!-- Start Side Menu --> */}
            <div className="side">
                <a href={handleDummy()} className="close-side"><i className="ti-close"> </i></a>
                <div className="widget">
                    <h4 className="title">Get in touch</h4>
                    <p>
                        Arrived compass prepare an on as. Reasonable particular on my it in sympathize. Size now easy eat hand how. Unwilling he departure elsewhere dejection at. Heart large seems may purse means few blind.
                    </p>
                    <a href={handleDummy()} className="btn btn-theme effect btn-sm" data-animation="animated slideInUp">Consultation</a>
                </div>
                <div className="widget">
                    <h4 className="title">Additional Links</h4>
                    <ul>
                        <li><a href={handleDummy()}>About</a></li>
                        <li><a href={handleDummy()}>Projects</a></li>
                        <li><a href={handleDummy()}>Login</a></li>
                        <li><a href={handleDummy()}>Register</a></li>
                    </ul>
                </div>
                <div className="widget social">
                    <h4 className="title">Connect With Us</h4>
                    <ul className="link">
                        <li className="facebook"><a href={handleDummy()}><i><FontAwesomeIcon icon={['fab', 'facebook-f']} /> </i></a></li>
                        <li className="twitter"><a href={handleDummy()}><i><FontAwesomeIcon icon={['fab', 'twitter']} /> </i></a></li>
                        <li className="linkedin"><a href={handleDummy()}><i><FontAwesomeIcon icon={['fab', 'linkedin']} /> </i></a></li>
                    </ul>
                </div>
            </div>
            {/* <!-- End Side Menu --> */}

    </>
)