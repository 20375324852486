import React from "react"
import { connect } from "react-redux";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ClutchLogo from '../../assets/images/clutch-char.inline.svg'
import {Link} from 'gatsby'


library.add(faPlus);

function handleDummy(e) {}


class FooterSection extends React.Component {
    showContactForm = (e) => {
        e.preventDefault();
        
        this.props.dispatch({
            type: 'CONTACT_FORM_ACTION',
            payload: {
                status: true,
                location: e.currentTarget.dataset.type
            },
        })
    }
  
    render() {
        return (
            <>
                {/* Star Footer
                =============================================*/}
                <footer className="bg-dark text-light">
                    <div className="container">
                        <div className="f-items default-padding">
                            <div className="row">
                                <div className="single-item col-lg-4 col-md-6 item">
                                    <div className="f-item about">
                                        <h4 className="widget-title">About Us</h4>
                                        <p>
                                            We bond western business and local IT experts with medical degrees together<br/>for the good of both.
                                        </p>
                                        <button className="btn btn-md left-icon btn-gradient" data-type="footer" onClick={this.showContactForm}><i><FontAwesomeIcon icon={['fas', 'plus']} /></i> Request a conversation</button>
                                        <div className="social">
                                            <h5>Get Us:</h5>
                                            <ul>
                                                <li className="clutch">
                                                    <a href="https://clutch.co/profile/sommer" rel="noreferrer" target="_blank" aria-label="Clutch"><i><ClutchLogo /></i></a>
                                                </li>
                                                <li className="linkedin">
                                                    <a href="https://www.linkedin.com/company/sommer-medical/" rel="noreferrer" target="_blank" aria-label="Linkedin"><i><FontAwesomeIcon icon={['fab', 'linkedin']} /></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="single-item col-lg-2 col-md-6 item">
                                    <div className="f-item link">
                                        <h4 className="widget-title">Site map</h4>
                                        <ul>
                                            <li>
                                                <Link to="/">For business</Link>
                                            </li>
                                            <li>
                                                <Link to="/careers">For career</Link>
                                            </li>
                                            <li>
                                                <Link to="/terms-and-conditions">Terms and conditions</Link>
                                            </li>
                                            <li>
                                                <Link to="/cookies-policy">Cookies Policy</Link>
                                            </li>
                                            <li className="invisible">
                                                <a href={handleDummy()}>Contact us</a>
                                            </li>
                                            <li className="invisible">
                                                <a href={handleDummy()}>Blog</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* <div className="single-item col-lg-2 col-md-6 item">
                                    <div className="f-item link">
                                        <h4 className="widget-title">Usefull Links</h4>
                                        <ul>
                                            <li>
                                                <a href={handleDummy()}>Ambulance</a>
                                            </li>
                                            <li>
                                                <a href={handleDummy()}>Emergency</a>
                                            </li>
                                            <li>
                                                <a href={handleDummy()}>Blog</a>
                                            </li>
                                            <li>
                                                <a href={handleDummy()}>Project</a>
                                            </li>
                                            <li>
                                                <a href={handleDummy()}>About Us</a>
                                            </li>
                                            <li>
                                                <a href={handleDummy()}>Contact</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}

                                <div className="single-item col-lg-4 col-md-6 item">
                                    <div className="f-item branches">
                                        <h4 className="widget-title">Contacts</h4>
                                        <div className="branches">
                                            <ul>
                                                <li>
                                                    <strong>Phone:</strong> 
                                                    <span><a href="tel:+16692134921">+16692134921</a></span>
                                                </li>
                                                <li>
                                                    <span><a href="tel:+380501094765">+380501094765</a></span>
                                                </li>
                                                <li>
                                                    <strong>Email:</strong> 
                                                    <span><a href="mailto:contact@sommer.consulting">contact@sommer.consulting</a></span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Footer Bottom*/}
                    <div className="footer-bottom">
                        <div className="container">
                            <div className="row align-center">
                                <div className="col-lg-6">
                                    <p>&copy; 2020 <strong>SOMMER</strong>.</p>
                                </div>
                                {/* <div className="col-lg-6 text-right link">
                                    <ul>
                                        <li>
                                            <a href={handleDummy()}>Terms</a>
                                        </li>
                                        <li>
                                            <a href={handleDummy()}>Privacy</a>
                                        </li>
                                        <li>
                                            <a href={handleDummy()}>Support</a>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </footer>
                {/* End Footer*/}
            </>
        )
    }
}
 
export default connect(null)(FooterSection);