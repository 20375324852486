import React from "react"
import ContactForm from "./contact-form";
// import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from "react-redux";

class ContactModal extends React.Component {


    handleClose = () => {
        this.props.dispatch({
            type: 'CONTACT_FORM_ACTION',
            payload: {
                status: false
            },
        })
    }
  
    render() {
        Modal.setAppElement('body');
        
        const { open, location, context } = this.props;
        const referrer = typeof document !== 'undefined' ? document.referrer : 'ssr';
        
        
        return (
            <Modal
                isOpen={open}
                onRequestClose={this.handleClose}
                shouldFocusAfterRender={false}
                // style={customStyles}
                className="contact-modal-content"
                overlayClassName="contact-modal-overlay"
                aria={{
                    labelledby: "heading",
                    // describedby: "full_description"
                }}>
                <h4 id="heading">Contact us</h4>
                {/* <div id="full_description">
                    <p>Description goes here.</p>
                </div> */}
                <ContactForm formRole={this.props.formRole} formReferrer={referrer} formClickLocation={location} formContext={context} />
            </Modal>
        )
    }
}


const mapStateToProps = store => {
    return {
        ...store.modalForm
    }
}

export default connect(mapStateToProps)(ContactModal);